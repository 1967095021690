<template>
  <b-card
    class="blog-edit-wrapper"
  >

    <!-- form -->
    <validation-observer ref="simpleRules">

      <b-form class="mt-2">
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Nom entreprise"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="company.name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Adresse"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="address"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="company.address"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Matricule fiscale"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="tax_identification_number"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="company.tax_identification_number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Tel1"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="phone1"
                rules="required|integer|length:8"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="company.phone1"
                  :state="errors.length > 0 ? false:null"
                  maxlength="8"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Tel2"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <b-form-input
                id="blog-edit-title"
                v-model="company.phone2"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Logo
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewEl"
                    :src="company.logo"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <small class="text-muted">Entrer le logo de votre entreprise.</small>
                  <b-card-text class="my-50">
                    <b-link id="blog-image-text" />
                  </b-card-text>
                  <div class="d-inline-block">
                    <validation-provider
                      #default="{ errors }"
                      name="logo"
                      rules="required"
                    >
                      <b-form-file
                        ref="refInputEl"
                        v-model="company.logo"
                        placeholder="Choose file"
                        :state="errors.length > 0 ? false:null"
                        @input="inputImageRenderer"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider></div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Enregistrer
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import storeAuth from '@/store/store'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BCard,
    BMedia,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    ValidationProvider,
    ValidationObserver,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      company: {
        name: '',
        address: '',
        phone1: '',
        phone2: '',
        logo: '',
        tax_identification_number: '',
      },
      required,
      blogFile: null,
      categoryOption: ['Fashion', 'Food', 'Gaming', 'Quote', 'Video'],
      statusOption: ['Published', 'Pending', 'Draft'],
      snowOption: {
        theme: 'snow',
      },
    }
  },
  created() {
    if (storeAuth.state.company != null) {
      this.company = storeAuth.state.company
    }
  },
  methods: {
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    async save() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          const formData = new FormData()
          formData.append('name', this.company.name)
          formData.append('address', this.company.address)
          formData.append('phone1', this.company.phone1)
          formData.append('phone2', this.company.phone2)
          formData.append('tax_identification_number', this.company.tax_identification_number)
          formData.append('logo', this.company.logo)
          if (storeAuth.state.company != null) {
            await axios
              .put(
                `/api/companies/update/${1}/`,
                formData,
              ).then(response => {
                setTimeout(() => {
                  this.showToast('success', 'top-center', 'Votre données modifiées avec succes')
                }, 1000)
                storeAuth.commit('setCompany',
                  response.data)
                this.$router.push({ name: 'dashboard' })
              })
          } else {
            await axios
              .post(
                '/api/companies/create/', formData,
              ).then(response => {
                setTimeout(() => {
                  this.showToast('success', 'top-center', 'Votre données ajoutées avec succes')
                }, 1000)
                storeAuth.commit('setCompany',
                  response.data)
                this.$router.push({ name: 'dashboard' })
              })
          }
        }
      })
    },
  },

  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
